export const coach = [
  {
    title: "Mi Equipo",
    path: "/players-sumary",
  },
];

export const player = [
  {
    title: "Mi perfil",
    path: "/settings",
  },  
   /*{
    title: "Información del jugador",
    path: "/player-profile",
  },*/
];

export const asistant = [
  {
    title: "Planilla",
    path: "/team-gear",
  },
  {
    title: "Administración de usuarios",
    path: "/all-users",
  },
  {
    title : "Administrar amonestaciones",
    path : "/all-warning"
  },
  {
    title : "Acumulado de amonestaciones",
    path : "/all-warning-count"
  }
];

export const admin = [
    {
      title: "Gestionar partidos",
      path: "/games-management",
    },
  ];

  export const referee = [
  ];

export const LINKS = [
  {
    title: "Opciones",
    icon: "table",
    pages: [],
  },
];

export const LINKS_old = [
  {
    title: "Opciones",
    icon: "table",
    pages: [
      {
        title: "Club summary",
        path: "/club-summary",
      },
      {
        title: "Game summary",
        path: "/game-summary",
      },
      {
        title: "Championships",
        path: "/championships",
      },
      {
        title: "League overview",
        path: "/league-overview",
      },
      {
        title: "Fans community",
        path: "/fans-community",
      },
      {
        title: "Statistics",
        path: "/statistics",
      },
      {
        title: "Error page",
        path: "/404",
      },
    ],
  },
  {
    title: "Matches",
    icon: "calendar",
    pages: [
      {
        title: "Match summary",
        path: "/match-summary",
      },
      {
        title: "Match overview",
        path: "/match-overview",
      },
    ],
  },
  {
    title: "Profile",
    icon: "users",
    pages: [
      {
        title: "Login",
        path: "/login",
      },
      {
        title: "Sign up",
        path: "/sign-up",
      },
      {
        title: "Player profile",
        path: "/player-profile",
      },
      {
        title: "Schedule",
        path: "/schedule",
      },
      {
        title: "Tickets",
        path: "/tickets",
      },
    ],
  },
  {
    title: "E-commerce",
    icon: "cart",
    pages: [
      {
        title: "Football store",
        path: "/football-store",
      },
      {
        title: "Brand store",
        path: "/brand-store",
      },
      {
        title: "Product details",
        path: "/product",
      },
    ],
  },
];
